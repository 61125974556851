import { loader, scene, world, carPositions } from "./script";
import { Obstacle } from "./obstacle";
import * as CANNON from "cannon-es";
import { Car } from "./car";

export class PoliceCar extends Obstacle {
    constructor(blockade, lane) {
        super();
        this.lane = lane;
        this.startingPosition = blockade.startingPosition;
        this.defaultPosition = blockade.defaultPosition;
        this.load();
    }

    load() {
        loader.load("/sport.fbx", (object) => {
            this.model = object;
            this.model.scale.set(0.25, 0.25, 0.25);
            if (this.lane === 0) this.model.rotation.set(0, Math.PI, 0);
            else this.model.rotation.set(0, 0, 0);
            this.model.position.set(carPositions[this.lane] + 130, 40, this.startingPosition);
            scene.add(this.model);

            const carMat = new CANNON.Material({ friction: 0, restitution: 0 })
            const cubeShape = new CANNON.Box(new CANNON.Vec3(200, 60, 100))
            this.body = new CANNON.Body({ mass: 1000, material: carMat })
            this.body.addShape(cubeShape)
            this.body.owner = this;
            this.body.position.x = this.model.position.x
            this.body.position.y = this.model.position.y + 20
            this.body.position.z = this.model.position.z
            this.body.quaternion.x = this.model.quaternion.x
            this.body.quaternion.y = this.model.quaternion.y
            this.body.quaternion.z = this.model.quaternion.z
            this.body.quaternion.w = this.model.quaternion.w
            this.body.collisionFilterGroup = 4; // Put the police car in group 4
            this.body.collisionFilterMask = 1;
            world.addBody(this.body)
            this.body.addEventListener("collide", function (e) {
                if (e.body.owner && e.body.owner instanceof Car) {
                    if (!e.body.owner.invincible && !e.body.owner.flying) e.body.owner.crash();
                    this.moveMode = 0.05;
                }
            });
        });
    }

    tick(car) {
        this.body.position.z = this.body.position.z + (0.05 * car.speed);
        this.model.quaternion.set(
            this.body.quaternion.x,
            this.body.quaternion.y,
            this.body.quaternion.z,
            this.body.quaternion.w
        )
        this.model.position.set(
            this.body.position.x,
            this.body.position.y - 20,
            this.body.position.z
        )
        if (this.model.position.z > 1000) {
            this.reset();
        }
    }

    reset() {
        this.model.visible = true;
        const randomPosition = Math.floor(Math.random() * carPositions.length);
        this.currentLane = randomPosition;
        this.model.position.set(carPositions[this.lane] + 100, 40, this.defaultPosition);
        if (this.body) {

            //this.body.velocity.set(0, 0, 0);
            //this.body.angularVelocity.set(0, 0, 0);

            // Position
            this.body.position.setZero();
            this.body.previousPosition.setZero();
            this.body.interpolatedPosition.setZero();
            this.body.initPosition.setZero();

            // orientation
            this.body.quaternion.set(0, 0, 0, 1);
            this.body.initQuaternion.set(0, 0, 0, 1);
            this.body.previousQuaternion.set(0, 0, 0, 1);
            this.body.interpolatedQuaternion.set(0, 0, 0, 1);

            // Velocity
            this.body.velocity.setZero();
            this.body.initVelocity.setZero();
            this.body.angularVelocity.setZero();
            this.body.initAngularVelocity.setZero();

            // Force
            this.body.force.setZero();
            this.body.torque.setZero();

            // Sleep state reset
            this.body.sleepState = 0;
            this.body.timeLastSleepy = 0;
            this.body._wakeUpAfterNarrowphase = false;

            if (this.lane === 0) this.model.rotation.set(0, Math.PI, 0);
            else this.model.rotation.set(0, 0, 0);
            this.body.quaternion.x = this.model.quaternion.x
            this.body.quaternion.y = this.model.quaternion.y
            this.body.quaternion.z = this.model.quaternion.z
            this.body.quaternion.w = this.model.quaternion.w

            this.body.position.set(
                this.model.position.x,
                this.model.position.y + 20,
                this.model.position.z
            )
        }
    };
}
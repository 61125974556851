export function checkCollision(object1, object2) {
    /*console.log(object1.modelGroup.position.z, object2.model.position.z)
    if (object1.modelGroup && object1.currentLane === object2.currentLane &&
        object1.modelGroup.position.z > object2.model.position.z + 150 &&
        object1.modelGroup.position.z < object2.model.position.z + 50 &&
        object1.modelGroup.position.y > object2.model.position.y - 150 &&
        object1.modelGroup.position.y < object2.model.position.y + 150
    ) {
        return true;
    }*/
    if (
        object1.currentLane === object2.currentLane &&
        object1.model.position.z > object2.model.position.z - 350 &&
        object1.model.position.z < object2.model.position.z /* &&
        object1.model.position.y > object2.model.position.y - 150 &&
        object1.model.position.y < object2.model.position.y + 150*/
    ) {
        return true;
    }
}
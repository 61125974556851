import { scene, loader, road } from "./script";
import * as THREE from "three";

const loadBuilding = (path, mask, position, scale = 15) => {
    loader.load(path, (obj) => {
        obj.position.set(250 * position, 0, -9600);
        obj.scale.set(scale, scale, scale);
        obj.rotation.set(0, 0, 0);
        obj.castShadow = true;
        obj.receiveShadow = true;
        const texture = new THREE.TextureLoader().load(mask);
        obj.children[0].material.emissiveMap = texture;
        obj.children[0].material.emissive = new THREE.Color(0xffffff);
        obj.children[0].material.emissiveIntensity = 2.5;
        scene.add(obj);
    });
};

export function addBuildings() {
    loadBuilding("/building_001/building_001.fbx", "/building_001/mask_emissive_building_001.jpg", -1);
    loadBuilding("/building_002/building_002.fbx", "/building_002/mask_emissive_building_002.jpg", -2);
    loadBuilding("/building_003/building_003.fbx", "/building_003/mask_emissive_building_003.jpg", 0);
    loadBuilding("/building_004/building_004.fbx", "/building_004/mask_emissive_building_004.jpg", 1);
    loadBuilding("/building_005/building_005.fbx", "/building_005/mask_emissive_building_005.jpg", 2, 10);
    loadBuilding("/building_001/building_001.fbx", "/building_001/mask_emissive_building_001.jpg", 3, 12);
    loadBuilding("/building_002/building_002.fbx", "/building_002/mask_emissive_building_002.jpg", -3, 10);
    loadBuilding("/building_003/building_003.fbx", "/building_003/mask_emissive_building_003.jpg", 4, 12);
    loadBuilding("/building_004/building_004.fbx", "/building_004/mask_emissive_building_004.jpg", 5, 10);
    loadBuilding("/building_005/building_005.fbx", "/building_005/mask_emissive_building_005.jpg", -4, 10);
}
import { Mud } from "./mud";
import { Ramp } from "./ramp";
import { Complex } from "./complex";

export class River extends Complex {
    constructor() {
        super();
        this.startingPosition = -20000;
        this.defaultPosition = -20000;
        this.currentPosition = -20000;
        const randomLaneArray = [0, 1, 2, 3, 4];
        this.elements = [new Ramp(this, randomLaneArray), new Ramp(this, randomLaneArray), new Mud(this, 0), new Mud(this, 1), new Mud(this, 2), new Mud(this, 3), new Mud(this, 4)];
    }
}
import { loader, scene, BLOOM_SCENE, mushroomPositions } from "./script";
import { Obstacle } from "./obstacle";
import { checkCollision } from "./collision";

export class Mud extends Obstacle {
    constructor(river, lane) {
        super();
        this.lane = lane;
        this.startingPosition = river.startingPosition;
        this.defaultPosition = river.defaultPosition;
        this.load();
    }

    load() {
        loader.load("/bush.fbx", (object) => {
            this.model = object;
            this.model.scale.set(2, 0.1, 2.5);
            this.model.position.set(mushroomPositions[this.lane], 10, this.defaultPosition);
            scene.add(this.model);
            this.model.children[0].layers.enable(BLOOM_SCENE);
        });
    }

    tick(car) {
        this.model.position.z = this.model.position.z + (0.05 * car.speed);
        if (checkCollision(car, this)) {
            if (!car.flying && !car.crashed) {
                car.crash();
            }
        }
        if (this.model.position.z > 1000) this.reset();
    }

    reset() {
        this.model.visible = true;
        this.defaultPosition = -20000;
        this.model.position.set(mushroomPositions[this.lane], 10, this.defaultPosition);
    };
}
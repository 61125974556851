import { PoliceCar } from "./policeCar";
import { Ramp } from "./ramp";
import { Complex } from "./complex";

export class Blockade extends Complex {
    constructor() {
        super();
        this.startingPosition = -30000;
        this.defaultPosition = -20000;
        this.currentPosition = this.startingPosition;
        const randomLaneArray = [0, 1, 3, 4];
        this.elements = [new Ramp(this, randomLaneArray), new PoliceCar(this, 0), new PoliceCar(this, 3)];
    }

    restart() {
        this.currentPosition = this.startingPosition;
        this.elements.forEach(element => {
            if (element instanceof PoliceCar) element.body.position.z = this.startingPosition;
            else if (element instanceof Ramp) element.model.position.z = this.startingPosition - 700;
        });
    }
}
import { loader, scene, world, groundMaterial, score, BLOOM_SCENE, ENTIRE_SCENE, gameOverUI, restartGame } from "./script";
import * as THREE from "three";
import * as CANNON from "cannon-es";
import gsap from "gsap";
import { EnemyCar } from "./enemyCar";

export class Car {
    constructor() {
        this.currentLane = 2;
        this.baseSpeed = 0;
        this.boostedSpeed = 0;
        this.speed = 0;
        this.maxSpeed = 880;
        this.flying = false;

        this.modelGroup = new THREE.Group();
        this.modelGroup.position.set(0, 40, 600);
        this.modelGroup.rotation.set(-Math.PI / 2, -Math.PI / 2, -Math.PI / 2);

        loader.load("/car_no_wheels.fbx", (object) => {
            this.model = object;
            this.model.scale.set(0.13, 0.13, 0.13);
            this.model.castShadow = true;
            this.modelGroup.add(this.model);

            const carMat = new CANNON.Material({ friction: 0, restitution: 0 })
            const cubeShape = new CANNON.Box(new CANNON.Vec3(250, 50, 100))
            this.body = new CANNON.Body({ mass: 1000, material: carMat })
            this.body.addShape(cubeShape)
            this.body.position.x = this.modelGroup.position.x
            this.body.position.y = this.modelGroup.position.y
            this.body.position.z = this.modelGroup.position.z
            this.body.quaternion.x = this.modelGroup.quaternion.x
            this.body.quaternion.y = this.modelGroup.quaternion.y
            this.body.quaternion.z = this.modelGroup.quaternion.z
            this.body.quaternion.w = this.modelGroup.quaternion.w
            this.body.collisionFilterGroup = 1; // Put the enemy car in group 2
            //this.body.collisionFilterMask = 1;
            world.addBody(this.body)
            this.body.owner = this;

            const carMat_ground = new CANNON.ContactMaterial(groundMaterial, carMat, { friction: 0.5, restitution: 0.0, contactEquationRelaxation: 1 });
            world.addContactMaterial(carMat_ground);
            const carMat_carMat = new CANNON.ContactMaterial(carMat, carMat, { friction: 0.5, restitution: 0.0, contactEquationRelaxation: 1 });
            world.addContactMaterial(carMat_carMat);

            this.addWheels();
            this.addLights();

        });
    }

    addLights() {
        this.lights = [this.addLight('red', 48), this.addLight('red', -48), this.addLight('yellow', 58), this.addLight('yellow', -58), this.addLight('white', 38), this.addLight('white', -38)];
    }

    addLight(color, z) {
        let width = 6;
        let length = 6;
        const light = new THREE.Mesh(
            new THREE.PlaneGeometry(width, length),
            new THREE.MeshPhysicalMaterial({
                color: color,
                roughness: 0.1,
            })
        );
        light.rotation.y = Math.PI / 2;

        light.position.x = 177.5;
        light.position.y = 58;
        light.position.z = z;

        light.castShadow = true;
        light.receiveShadow = true;
        this.modelGroup.add(light);
        scene.add(this.modelGroup);
        light.layers.enable(BLOOM_SCENE);
        return light;
    }

    addWheels() {
        this.wheels = {}
        loader.load("/wheel_left.fbx", (object) => {
            object.position.set(-110, 22, 70);
            object.scale.set(0.13, 0.13, 0.13);
            object.castShadow = true;
            this.modelGroup.add(object);
            this.wheels.frontleft = object;
        });
        loader.load("/wheel_right.fbx", (object) => {
            object.position.set(-110, 22, -70);
            object.scale.set(0.13, 0.13, 0.13);
            object.castShadow = true;
            this.modelGroup.add(object);
            this.wheels.frontright = object;
        });
        loader.load("/wheel_left.fbx", (object) => {
            object.position.set(110, 22, 70);
            object.scale.set(0.13, 0.13, 0.13);
            object.castShadow = true;
            this.modelGroup.add(object);
            this.wheels.backleft = object;
        });
        loader.load("/wheel_right.fbx", (object) => {
            object.position.set(110, 22, -70);
            object.scale.set(0.13, 0.13, 0.13);
            object.castShadow = true;
            this.modelGroup.add(object);
            this.wheels.backright = object;
        });
    }

    tick() {
        /**
        * WHEEL ROTATION
        */
        Object.values(this.wheels).forEach((wheel) => {
            if (wheel && wheel.rotation) wheel.rotation.z += 0.01 * (this.speed / 10);
        });

        this.accelerate();
        this.adjustModelPosition();
        this.resetRotation();
    }

    accelerate() {
        if (this.baseSpeed < this.maxSpeed && !this.crashed) {
            this.baseSpeed += 1.5;
            this.speed += 1.5;
            this.boostedSpeed += 2.5;
        }
        if (this.speed > this.maxSpeed && !this.invincible) {
            this.speed -= 2.5;
        }
    }

    boost() {
        if (!this.crashed) {
            this.model.children[0].layers.enable(BLOOM_SCENE);
            if (this.boostTimer) clearTimeout(this.boostTimer);
            this.speed = this.boostedSpeed;
            this.becameInvincible();
            this.boostTimer = setTimeout(() => {
                this.stopBoost();
            }, 5000);
        }
    }

    stopBoost() {
        this.model.children[0].layers.toggle(BLOOM_SCENE);
        this.removeInvincible();
    }

    becameInvincible() {
        this.invincible = true;
    }

    removeInvincible() {
        this.invincible = false;
    }

    crash() {
        if (!this.crashed && !this.invincible) {
            console.log('car crash')
            this.crashed = true;

            if (this.baseSpeed > 200) {
                this.baseSpeed = 200;
                this.speed = 200;
                this.boostedSpeed = 300;
            }
            this.body.quaternion.setFromEuler(0, -Math.PI / 2, -0.1);
            let force = new CANNON.Vec3(0, 1000, 0);
            let point = new CANNON.Vec3(0, 0, 0);
            this.body.applyLocalForce(force, point)

            gameOverUI.style.visibility = "visible";
            setTimeout(() => {
                restartGame();
            }, 3000);
        }
    }

    reduceSpeed() {
        if (!this.invincible && !this.flying) {
            if (this.baseSpeed > 400) {
                this.baseSpeed = 400;
                this.speed = 400;
                this.boostedSpeed = 600;
            }
        }
    }

    fly() {
        if (!this.flying) {
            this.body.quaternion.setFromEuler(0, -Math.PI / 2, 0.06);
            let force = new CANNON.Vec3(0, 25000000 * this.speed / 800, 0);
            let point = new CANNON.Vec3(0, 0, 0);
            this.body.applyLocalForce(force, point)
            this.flying = true;
            setTimeout(() => {
                this.stopFly();
            }, 1300);
        }
    }

    stopFly() {
        this.resetRotation();
        this.flying = false;
        gsap.to(this.body.position, { y: 40 });
    }

    adjustModelPosition() {
        this.modelGroup.quaternion.set(
            this.body.quaternion.x,
            this.body.quaternion.y,
            this.body.quaternion.z,
            this.body.quaternion.w
        )
        if (this.body.position.y < 40 && !this.crashed) this.body.position.y = 40;
        if (!this.crashed) this.body.position.z = 600;
        this.modelGroup.position.set(
            this.body.position.x,
            this.body.position.y,
            this.body.position.z
        )

    }

    resetRotation() {
        if (!this.crashed && !this.flying) {
            this.body.quaternion.setFromEuler(0, -Math.PI / 2, 0);
            this.body.velocity.setZero();
            this.body.initVelocity.setZero();
            this.body.angularVelocity.setZero();
            this.body.initAngularVelocity.setZero();

            // Force
            this.body.force.setZero();
            this.body.torque.setZero();
        }
    }
}

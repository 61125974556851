import { loader, scene, mushroomPositions } from "./script";

export class Obstacle {
    constructor() {
        this.defaultPosition = -10000;
        const randomPosition = Math.floor(Math.random() * mushroomPositions.length);
        //this.model.position.set(mushroomPositions[randomPosition], 0, -10000);
        this.currentLane = randomPosition;
        //this.load();
    }

    /*load() {
        loader.load("/bush.fbx", (object) => {
            this.model = object;
            this.model.name = "current_mush";
            this.model.scale.set(1, 1, 1);
            this.model.position.set(mushroomPositions[Math.floor(Math.random() * mushroomPositions.length)], 0, -10000);
            scene.add(this.model);
        });
    }*/

    hide() {
        this.model.visible = false;
    }

    reset() {
        this.model.visible = true;
        const randomPosition = Math.floor(Math.random() * mushroomPositions.length);
        this.model.position.set(mushroomPositions[randomPosition], 0, this.defaultPosition);
        this.currentLane = randomPosition;
    };
}
import "./style.css";
import * as THREE from "three";
import { Sky } from "three/examples/jsm/objects/Sky";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import gsap from "gsap";
import { Mushroom } from "./mushroom";
import { Car } from "./car";
import * as CANNON from "cannon-es";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { drawLanes } from "./lanes";
import { addEnvironment, guardrails, lightPoles, mountains, palmTrees, trees } from "./environment";
import { addBuildings } from "./buildings";
import { EnemyCar } from "./enemyCar";
import { checkCollision } from "./collision";
import { River } from "./river";
import { Barricade } from "./barricade";
import { Phase } from "./phases";
import { Blockade } from "./blockade";

//BLOOM
export const ENTIRE_SCENE = 0;
export const BLOOM_SCENE = 1;

const bloomLayer = new THREE.Layers();
bloomLayer.set(BLOOM_SCENE);

const params = {
  exposure: 1.5,
  bloomStrength: 1.5,
  bloomThreshold: 0,
  bloomRadius: 0,
  scene: "Scene with Glow"
};

export const scene = new THREE.Scene();
/**
 * GLOBALE VARIABLES
 */
export let carPositions = [-500, -250, 0, 250, 500];
export let mushroomPositions = [-600, -300, 0, 300, 600];
let temp = new THREE.Vector3();
let sun = new THREE.Vector3();
let sky;

/**
 * PHYSICS
 */
export const world = new CANNON.World()
world.gravity.set(0, -982, 0)

/**
 * CANVAS - HTML ELEMENT
 */
const startUI = document.getElementsByClassName("start")[0];
startUI.onclick = function startGame() {
  scoreUI.style.visibility = "visible";
  speedUI.style.visibility = "visible";
  startUI.style.visibility = "hidden";
  //gameOverUI.style.visibility = "hidden";
  car = new Car();
}

const canvas = document.getElementById("canvas");
export const scoreText = document.getElementById("score");
export const highScoreText = document.getElementById("high_score");
export const speedText = document.getElementById("speed");

export const scoreUI = document.getElementsByClassName("score")[0];
scoreUI.style.visibility = "hidden";
export const highScoreUI = document.getElementsByClassName("high_score")[0];
highScoreUI.style.visibility = "hidden";
export const speedUI = document.getElementsByClassName("speed")[0];
speedUI.style.visibility = "hidden";
export const gameOverUI = document.getElementsByClassName("game_over")[0];
gameOverUI.style.visibility = "hidden";

scoreText.innerText = 0;
speedText.innerText = '0 km/h';

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

/**
 * GROUP - INFINITE WALKING GROUP
 */
export const group = new THREE.Group();

const camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 100, 11500);
camera.position.set(0, 220, 1100);
camera.lookAt(scene.position);

const darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });
const materials = {};

/**
 * RENDERER
 */

const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 0.5;
document.body.appendChild(renderer.domElement);


//scene.add(new THREE.AmbientLight(0x404040));

const renderScene = new RenderPass(scene, camera);

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
bloomPass.threshold = params.bloomThreshold;
bloomPass.strength = params.bloomStrength;
bloomPass.radius = params.bloomRadius;

const bloomComposer = new EffectComposer(renderer);
bloomComposer.renderToScreen = false;
bloomComposer.addPass(renderScene);
bloomComposer.addPass(bloomPass);

const finalPass = new ShaderPass(
  new THREE.ShaderMaterial({
    uniforms: {
      baseTexture: { value: null },
      bloomTexture: { value: bloomComposer.renderTarget2.texture }
    },
    vertexShader: document.getElementById('vertexshader').textContent,
    fragmentShader: document.getElementById('fragmentshader').textContent,
    defines: {}
  }), "baseTexture"
);
finalPass.needsSwap = true;

const finalComposer = new EffectComposer(renderer);
finalComposer.addPass(renderScene);
finalComposer.addPass(finalPass);

setupScene();

function setupScene() {

  scene.traverse(disposeMaterial);
  scene.children.length = 0;

  render();

}

function disposeMaterial(obj) {

  if (obj.material) {

    obj.material.dispose();

  }

}

function render() {

  switch (params.scene) {

    case 'Scene only':
      renderer.render(scene, camera);
      break;
    case 'Glow only':
      renderBloom(false);
      break;
    case 'Scene with Glow':
    default:
      // render scene with bloom
      renderBloom(true);

      // render the entire scene, then render bloom scene on top
      finalComposer.render();
      break;

  }

}

function renderBloom(mask) {

  if (mask === true) {

    scene.traverse(darkenNonBloomed);
    bloomComposer.render();
    scene.traverse(restoreMaterial);

  } else {

    camera.layers.set(BLOOM_SCENE);
    bloomComposer.render();
    camera.layers.set(ENTIRE_SCENE);

  }

}

function darkenNonBloomed(obj) {

  if (obj.isMesh && bloomLayer.test(obj.layers) === false) {

    materials[obj.uuid] = obj.material;
    obj.material = darkMaterial;

  }

}

function restoreMaterial(obj) {

  if (materials[obj.uuid]) {

    obj.material = materials[obj.uuid];
    delete materials[obj.uuid];

  }

}

////////////////////////////////////////////////

export function restartGame() {
  console.log('RESTARTING GAME');
  if (score > highScore) highScore = score;
  score = 0;
  highScoreText.innerText = highScore;
  scoreText.innerText = 0;
  ambient.intensity = 0.1;

  scene.remove(car.modelGroup);
  car = {};
  mush.reset();
  enemyCar.reset();
  barricade.reset();
  river.reset();
  blockade.reset();
  blockade.restart();

  Object.values(phase).forEach((elem) => {
    elem.complete = false;
    elem.shownIndex = 20;
    elem.objects.forEach(array => {
      array.forEach(element => {
        if (element.visible) {
          element.visible = false;
        }
      });
    });
  });
  currentPhase = phase[0];

  scoreUI.style.visibility = "hidden";
  highScoreUI.style.visibility = "visible";
  speedUI.style.visibility = "hidden";
  startUI.style.visibility = "visible";
  gameOverUI.style.visibility = "hidden";
}

/**
 * LIGHTS
 */
// Directional Light
const directionalLight = new THREE.DirectionalLight(0xffffff);
directionalLight.position.set(0, 20, 30);
scene.add(directionalLight);

// Ambient Light - soft light from all directions
const ambient = new THREE.AmbientLight(0xffffff, 0.1);
scene.add(ambient);

/**
 * SUN
 */
const geometry = new THREE.CircleGeometry(800, 128);
const material = new THREE.MeshPhongMaterial({
  color: "#ffa200",
  reflectivity: 5,
  shininess: 20,
  //emissive: "#ff0000",
  //specular: "#150050",
});
const circle = new THREE.Mesh(geometry, material);
circle.position.y = -800;
circle.position.z = -9700;
scene.add(circle);
circle.layers.enable(BLOOM_SCENE);

/**
 * ROAD LANES
 */

drawLanes();

/**
 * SKY
 */
const initSky = () => {
  sky = new Sky();
  sky.scale.setScalar(450000);
  scene.add(sky);

  const effectController = {
    turbidity: 20,
    rayleigh: 4,
    mieCoefficient: 0,
    mieDirectionalG: 1,
    elevation: 3,
    azimuth: 180,
    exposure: renderer.toneMappingExposure,
  };

  function guiChanged() {
    const uniforms = sky.material.uniforms;
    uniforms["turbidity"].value = effectController.turbidity;
    uniforms["rayleigh"].value = effectController.rayleigh;
    uniforms["mieCoefficient"].value = effectController.mieCoefficient;
    uniforms["mieDirectionalG"].value = effectController.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad(90 - effectController.elevation);
    const theta = THREE.MathUtils.degToRad(effectController.azimuth);

    sun.setFromSphericalCoords(1, phi, theta);

    uniforms["sunPosition"].value.copy(sun);
    renderer.toneMappingExposure = effectController.exposure;
    renderer.render(scene, camera);
  }

  guiChanged();
};

/**
 * GROUND
 */
const groundGeo = new THREE.PlaneGeometry(10000, 30000);
const groundMat = new THREE.MeshPhysicalMaterial({
  color: "#3F0071",
});
const ground = new THREE.Mesh(groundGeo, groundMat);
ground.position.y = -3;
ground.rotation.x = -Math.PI / 2;
ground.receiveShadow = true;
ground.castShadow = true;
group.add(ground);

export const groundMaterial = new CANNON.Material({ friction: 0, restitution: 0 })
const planeShape = new CANNON.Plane()
const planeBody = new CANNON.Body({ mass: 0, material: groundMaterial })
planeBody.addShape(planeShape)
planeBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2)
planeBody.position.x = ground.position.x
planeBody.position.y = ground.position.y
planeBody.position.z = ground.position.z
world.addBody(planeBody)

/**
 * ROAD
 */
export const road = new THREE.Mesh(
  new THREE.PlaneGeometry(1550, 30000),
  new THREE.MeshPhysicalMaterial({
    color: "#150050",
    roughness: 0.5,
  })
);
road.rotation.x = -Math.PI / 2;
road.castShadow = true;
road.receiveShadow = true;
group.add(road);

/**
 * CAR - MAIN PLAYER
 */

export const loader = new FBXLoader();

let car
/*setTimeout(() => {
  scoreUI.style.visibility = "visible";
  speedUI.style.visibility = "visible";
  car = new Car();
}, 10000);*/

/*
 * ENVIRONMENT
 */
addEnvironment();

/*
 * CITY
 */
addBuildings();

/**
 * ADD MUSHROOM
 */
let mush = new Mushroom;

/**
 * ADD ENEMY CAR
 */
export let enemyCar = new EnemyCar;

/**
 * ADD BARRICADE
 */
export let barricade = new Barricade;

/**
 * ADD RIVER
 */
let river = new River;

/**
 * ADD BLOCKADE
 */
let blockade = new Blockade;

scene.add(group);

initSky();

/**
 * CAMERA CONTROLL
 */
const controls = new OrbitControls(camera, renderer.domElement);
controls.addEventListener("change", render);
controls.enablePan = false;
controls.enableRotate = false;
controls.enableZoom = false;

/**----------------------------------------------------------------------------- */
/**
 * Current car position
 */


/**
 * CONTROL MOVEMAENTS AT DESKTOP - KEYBOARD ARROWS KEYS DETECTION
 */
function DesktopMovements(e) {
  this.onkeydown = null;
  switch (e.keyCode) {
    case 39:
      if (car.currentLane === 0) {
        car.currentLane = 1;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 1) {
        car.currentLane = 2;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 2) {
        car.currentLane = 3;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 3) {
        car.currentLane = 4;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      }

      break;
    case 37:
      if (car.currentLane === 4) {
        car.currentLane = 3;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 3) {
        car.currentLane = 2;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 2) {
        car.currentLane = 1;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      } else if (car.currentLane === 1) {
        car.currentLane = 0;
        gsap.to(car.body.position, { x: carPositions[car.currentLane] });
      }
      break;
  }
}

/**
 * CONTROLL AND MOVEMENTS ON MOBILE
 */
function MobileMovements(e) {
  this.ontouchstart = null;
  if (e.targetTouches[0].clientX > 0 && e.targetTouches[0].clientX < window.innerWidth / 2 && car.currentLane > 0) {
    car.currentLane--;
    gsap.to(car.body.position, { x: carPositions[car.currentLane] });
  } else if (
    e.targetTouches[0].clientX > window.innerWidth / 2 &&
    e.targetTouches[0].clientX < window.innerWidth &&
    car.currentLane < carPositions.length - 1
  ) {
    car.currentLane++;
    gsap.to(car.body.position, { x: carPositions[car.currentLane] });
  }
}

if (window.innerWidth < 500) {
  //sizes.width = 500;
  camera.position.set(0, 400, 2100);

  window.ontouchstart = MobileMovements;

  window.ontouchend = function (e) {
    this.ontouchstart = MobileMovements;
  };
}
else {
  window.onkeydown = DesktopMovements;

  window.onkeyup = function (e) {
    this.onkeydown = DesktopMovements;
  };

  window.ontouchstart = MobileMovements;

  window.ontouchend = function (e) {
    this.ontouchstart = MobileMovements;
  };
}

/**
 * RESPONSIVE HANDLER FUNCTION
 */
window.addEventListener("resize", () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
  render();
});

/**
 * FULLSCREEN FUNCTIONALITY - KeyF
 */
window.addEventListener("keydown", (e) => {
  if (e.code === "KeyF") {
    if (!document.fullscreenElement) {
      canvas.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
});

const clock = new THREE.Clock()
let delta
let score = 0;
let highScore = 0;

let phase = {};
phase[0] = new Phase(0, [], 1000)
phase[1] = new Phase(1, [guardrails], 2000)
phase[2] = new Phase(2, [guardrails, mountains], 3000)
phase[3] = new Phase(3, [guardrails, mountains, trees], 4000)
phase[4] = new Phase(4, [guardrails, mountains, trees, palmTrees], 5000)
phase[5] = new Phase(5, [guardrails, mountains, trees, palmTrees, lightPoles], 0)

let currentPhase = phase[0];

/**
 * THE TICK FUNCTION - 60 FPS
 */
const animate = () => {
  delta = Math.min(clock.getDelta(), 0.1)
  world.step(delta)

  if (circle.position.y < 2200) circle.position.y = -800 + score * 0.05;
  if (ambient.intensity < 0.6) ambient.intensity = 0.1 + score / 50000;

  if (car && car.model) {
    /**
    * ENVIRONMENT GROUP MOVEMENT
    */
    group.position.z = group.position.z + (0.05 * car.speed);
    if (group.position.z > 1000) {
      group.position.z -= 1000;
      /**
      * GROUP OBJECTS SHOW ON CHANGING PHASE
      */
      if (!currentPhase.complete) {
        currentPhase.objects.forEach(array => {
          array.forEach((element, index) => {
            if (!element.visible && index >= currentPhase.shownIndex) {
              element.visible = true;
            }
            if (currentPhase.shownIndex < 0) {
              currentPhase.complete = true;
            }
          });
        });
        currentPhase.shownIndex -= 2;
      }
    }

    //SCORE UPDATE
    score += Math.round(car.speed / 400);

    //INTERFACE TEXT UPDATE
    scoreText.innerText = score;
    speedText.innerText = Math.round(car.speed / 4) + ' km/h';

    // NEXT PHASE
    if (score > currentPhase.maxScore && currentPhase.number < currentPhase.number + 1) {
      if (phase[currentPhase.number + 1]) currentPhase = phase[currentPhase.number + 1];
    }

    car.tick();

  }
  else {
    /**
    * ENVIRONMENT GROUP MOVEMENT
    */
    group.position.z = group.position.z + 20;
    if (group.position.z > 1000) {
      group.position.z -= 1000;
    }
  }

  /**
   * COLLIDE THE MUSHROOM
   */
  if (mush && mush.model && car && car.model) {
    mush.model.position.z = mush.model.position.z + (0.05 * car.speed);
    if (checkCollision(car, mush)) {
      mush.hide();
      car.boost();
    }
    if (mush.model.position.z > 10000) mush.reset();
  }

  if (enemyCar && enemyCar.model && car && car.model) {
    enemyCar.tick(car);
  }

  if (barricade && barricade.modelGroup && car && car.model) {
    barricade.tick(car);
  }

  if (river && car && car.model) {
    river.tick(car);
  }

  if (blockade && car && car.model) {
    blockade.tick(car);
  }

  if (car) {
    if (car.flying || car.crashed) {
      window.onkeydown = null;
      window.ontouchstart = null;
    } else {
      window.onkeydown = DesktopMovements;
      window.ontouchstart = MobileMovements;
    }
  }

  temp.setFromMatrixPosition(camera.matrixWorld);
  controls.update();
  render();

  requestAnimationFrame(animate);
};
animate();
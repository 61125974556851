import { loader, scene, BLOOM_SCENE, mushroomPositions } from "./script";
import { Obstacle } from "./obstacle";

export class Mushroom extends Obstacle {
    constructor() {
        super();
        this.defaultPosition = -57000;
        this.load();
    }

    load() {
        loader.load("/mushroom.fbx", (object) => {
            this.model = object;
            this.model.scale.set(10, 10, 10);
            this.model.position.set(mushroomPositions[this.currentLane], 0, this.defaultPosition);
            scene.add(this.model);
            this.model.children[0].layers.enable(BLOOM_SCENE);
        });
    }
}
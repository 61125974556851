import { loader, scene, world, carPositions, BLOOM_SCENE, ramp } from "./script";
import * as THREE from "three";
import { Obstacle } from "./obstacle";
import * as CANNON from "cannon-es";
import { Car } from "./car";
import { EnemyCar } from "./enemyCar";

export class Barricade extends Obstacle {
    constructor() {
        super();
        this.modelGroup = new THREE.Group();
        this.defaultPosition = -13000;
        this.modelGroup.position.set(carPositions[this.currentLane], 40, this.defaultPosition);
        this.modelGroup.rotation.set(0, 0, 0);
        this.load();
    }

    load() {
        loader.load("/barricade.fbx", (object) => {
            this.model = object;
            this.model.scale.set(10, 10, 10);
            this.modelGroup.add(this.model);

            const carMat = new CANNON.Material({ friction: 0, restitution: 0 })
            const cubeShape = new CANNON.Box(new CANNON.Vec3(100, 40, 60))
            this.body = new CANNON.Body({ mass: 0.1, material: carMat })
            this.body.addShape(cubeShape)
            this.body.owner = this;
            this.body.position.x = this.modelGroup.position.x
            this.body.position.y = this.modelGroup.position.y + 30;
            this.body.position.z = this.modelGroup.position.z
            this.body.quaternion.x = this.modelGroup.quaternion.x
            this.body.quaternion.y = this.modelGroup.quaternion.y
            this.body.quaternion.z = this.modelGroup.quaternion.z
            this.body.quaternion.w = this.modelGroup.quaternion.w
            world.addBody(this.body)
            this.body.addEventListener("collide", function (e) {
                if (e.body.owner && e.body.owner instanceof Car) {
                    if (!e.body.owner.invincible && !e.body.owner.flying) e.body.owner.crash();
                }
                else if (e.body.owner && e.body.owner instanceof EnemyCar) {
                    setTimeout(() => {
                        e.target.owner.reset();
                    }, 1000);
                }
            });
            this.lights = [this.addLight('red', 76.5), this.addLight('red', -76.5)];
        });
    }

    addLight(color, x) {
        let top = 12;
        let bottom = 12;
        let height = 20;
        const light = new THREE.Mesh(
            new THREE.CylinderGeometry(top, bottom, height),
            new THREE.MeshPhysicalMaterial({
                color: color,
                roughness: 0.1,
            })
        );

        light.position.x = x;
        light.position.y = 115;
        light.position.z = 0;

        light.castShadow = true;
        light.receiveShadow = true;
        this.modelGroup.add(light);
        scene.add(this.modelGroup);
        light.layers.enable(BLOOM_SCENE);
        return light;
    }

    tick(car) {
        this.body.position.z = this.body.position.z + (0.05 * car.speed);
        this.modelGroup.quaternion.set(
            this.body.quaternion.x,
            this.body.quaternion.y,
            this.body.quaternion.z,
            this.body.quaternion.w
        )
        this.modelGroup.position.set(
            this.body.position.x,
            this.body.position.y - 30,
            this.body.position.z
        )
        if (this.modelGroup.position.z > 1000 || this.modelGroup.position.z < this.defaultPosition) {
            this.moveMod = 0.02;
            this.reset();
        }
    }

    reset() {
        this.modelGroup.visible = true;
        const randomPosition = Math.floor(Math.random() * carPositions.length);
        this.currentLane = randomPosition;
        this.modelGroup.position.set(carPositions[randomPosition], 40, this.defaultPosition);
        if (this.body) {

            //this.body.velocity.set(0, 0, 0);
            //this.body.angularVelocity.set(0, 0, 0);

            // Position
            this.body.position.setZero();
            this.body.previousPosition.setZero();
            this.body.interpolatedPosition.setZero();
            this.body.initPosition.setZero();

            // orientation
            this.body.quaternion.set(0, 0, 0, 1);
            this.body.initQuaternion.set(0, 0, 0, 1);
            this.body.previousQuaternion.set(0, 0, 0, 1);
            this.body.interpolatedQuaternion.set(0, 0, 0, 1);

            // Velocity
            this.body.velocity.setZero();
            this.body.initVelocity.setZero();
            this.body.angularVelocity.setZero();
            this.body.initAngularVelocity.setZero();

            // Force
            this.body.force.setZero();
            this.body.torque.setZero();

            // Sleep state reset
            this.body.sleepState = 0;
            this.body.timeLastSleepy = 0;
            this.body._wakeUpAfterNarrowphase = false;

            this.modelGroup.rotation.set(0, 0, 0);
            this.body.quaternion.x = this.modelGroup.quaternion.x
            this.body.quaternion.y = this.modelGroup.quaternion.y
            this.body.quaternion.z = this.modelGroup.quaternion.z
            this.body.quaternion.w = this.modelGroup.quaternion.w

            this.body.position.set(
                this.modelGroup.position.x,
                this.modelGroup.position.y + 30,
                this.modelGroup.position.z
            )
        }
    };
}
export function changePhase() {

}

export class Phase {
    constructor(number, array, maxScore) {
        this.number = number;
        this.objects = array;
        this.maxScore = maxScore;
        this.shownIndex = 20;
        this.complete = false;
    }
}
import { group, loader, road } from "./script";
import * as THREE from "three";

export const loadTree = (array, path, gap, y, z, scale, emissive = false, rotation = Math.sign(y) * Math.PI / 2) => {
    loader.load(path, (obj) => {
        obj.position.set(-road.geometry.parameters.width / y, 0, z - gap * 500);
        obj.scale.set(scale, scale, scale);
        obj.rotation.set(0, rotation, 0);
        obj.castShadow = true;
        obj.receiveShadow = true;
        if (emissive) {
            const texture = new THREE.TextureLoader().load("mask_emissive_palm_tree.jpg");
            obj.children[0].material.emissiveMap = texture;
            obj.children[0].material.emissive = new THREE.Color(0x03fc03);
            obj.children[0].material.emissiveIntensity = 2.5;
        }
        obj.visible = false;
        array.push(obj);
        group.add(obj);
    });
};

export const lightPoles = [];
export const guardrails = [];
export const trees = [];
export const palmTrees = [];
export const mountains = [];

export function addEnvironment() {
    for (let i = 0; i <= 10; i++) {
        loadTree(lightPoles, "/light_pole.fbx", i * 2, 1.8, 700, 22, false, 0);
        loadTree(guardrails, "/guardrail.fbx", i * 2, 1.9, 700, 20);
        loadTree(trees, "/tree.fbx", i * 2, 1, 700, 20);
        loadTree(palmTrees, "/palm_tree.fbx", i * 2, 1.2, 700, 20, true);
        loadTree(mountains, "/mountain_001.fbx", i * 2, 0.2, -4000, 200);
        loadTree(lightPoles, "/light_pole.fbx", i * 2, -1.8, 700, 22, false, Math.PI / 2);
        loadTree(guardrails, "/guardrail.fbx", i * 2, -1.9, 700, 20);
        loadTree(trees, "/tree.fbx", i * 2, -1, 700, 20);
        loadTree(palmTrees, "/palm_tree.fbx", i * 2, -1.2, 700, 20, true);
        loadTree(mountains, "/mountain_001.fbx", i * 2, -0.2, -4000, 200);
    }
}
import { Obstacle } from "./obstacle";

export class Complex extends Obstacle {
    constructor() {
        super();
    }

    tick(car) {
        this.currentPosition = this.currentPosition + (0.05 * car.speed);
        this.elements.forEach(element => {
            if (element && element.model) {
                element.tick(car);
            }
        });
        if (this.currentPosition > 1000) this.reset();
    }

    reset() {
        this.currentPosition = this.defaultPosition;
        this.elements.forEach(element => {
            element.reset();
        });
    }
}
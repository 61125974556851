import * as THREE from "three";
import { BLOOM_SCENE, group } from "./script";

function drawLane(color, width, length, positionX, positionZ) {
    const lane = new THREE.Mesh(
        new THREE.PlaneGeometry(width, length),
        new THREE.MeshPhysicalMaterial({
            color: color,
            roughness: 0.5,
        })
    );
    lane.rotation.x = -Math.PI / 2;
    lane.position.x = positionX;
    lane.position.z = positionZ;
    lane.position.y = 1;
    lane.castShadow = true;
    lane.receiveShadow = true;
    group.add(lane);
    lane.layers.enable(BLOOM_SCENE);
    return lane;
}

export function drawLanes() {
    const rightLane = drawLane("red", 20, 30000, 775, 0);
    const leftLane = drawLane("red", 20, 30000, -775, 0);
    const rightMidLane = drawLane("#ff3c00", 10, 30000, 155, 0);
    const leftMidLane = drawLane("#ff3c00", 10, 30000, -155, 0);

    for (let i = 0; i <= 20; i++) {
        drawLane("yellow", 10, 200, 465, 700 - i * 500);
        drawLane("yellow", 10, 200, -465, 700 - i * 500);
    }

    //const leftDisLane = drawLane(10, 1000, 465, -5000);

}


import { loader, scene, mushroomPositions } from "./script";
import { Obstacle } from "./obstacle";
import { checkCollision } from "./collision";

export class Ramp extends Obstacle {
    constructor(complex, randomLaneArray) {
        super();
        this.randomLaneArray = randomLaneArray;
        this.currentLane = this.randomLaneArray[Math.floor(Math.random() * this.randomLaneArray.length)];
        this.startingPosition = complex.startingPosition + 700;
        this.defaultPosition = complex.defaultPosition + 700;
        this.load();
    }

    load() {
        loader.load("/ramp.fbx", (object) => {
            this.model = object;
            this.model.scale.set(18, 18, 18);
            this.model.position.set(mushroomPositions[this.currentLane], 0, this.startingPosition);
            scene.add(this.model);
        });
    }

    tick(car) {
        this.model.position.z = this.model.position.z + (0.05 * car.speed);
        if (checkCollision(car, this)) {
            car.fly();
        }
        if (this.model.position.z > 1000) this.reset();
    }

    reset() {
        this.model.visible = true;
        this.currentLane = this.randomLaneArray[Math.floor(Math.random() * this.randomLaneArray.length)];
        this.model.position.set(mushroomPositions[this.currentLane], 0, this.defaultPosition);
    };
}